<template>
  <div class="app_con">
    <div class="p-20">
      <el-table :data="agentCodeList" border style="width: 100%" row-key="id">
        <el-table-column prop="agentCode" label="agentCode"> </el-table-column>
        <el-table-column prop="agentName" label="agentName"> </el-table-column>
        <el-table-column prop="payAccountName" label="支付宝"></el-table-column>
        <el-table-column prop="switch" label="开关状态" sortable>
          <template slot-scope="props"
            ><span :class="props.row.switch == '关闭' ? 'font-red' : ''">
              {{ props.row.switch }}</span
            ></template
          >
        </el-table-column>
        <el-table-column label="消息状态" prop="status" sortable>
          <template slot-scope="props"
            ><span :class="props.row.status == '有新消息' ? 'font-red' : ''">
              {{ props.row.status }}</span
            ></template
          >
        </el-table-column>
      </el-table>
    </div>
    <div class="audioCon">
      <audio src="/audio/kf.mp3" controls ref="audio" id="audio"></audio>
    </div>
    <div class="audioCon">
      <audio
        src="/audio/close.mp3"
        controls
        ref="audio"
        id="audioClose"
      ></audio>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      agentCodeList: [],
      loopRun: true,
      isPlay: false,
      isClose: false,
    };
  },
  created() {
    this.getListAll();
  },
  mounted() {
    let _this = this;
    const timer = window.setInterval(() => {
      setTimeout(function () {
        // chooseProduct 自己封装的axios请求
        if (_this.loopRun) {
          _this.batchGetMessage();
        }
      }, 0);
    }, 30000);
    // 清除定时器
    this.$once("hook:beforeDestroy", () => {
      clearInterval(timer);
    });
  },
  methods: {
    audioPlay() {
      if (this.isPlay) {
        var audio = document.querySelector("#audio");
        audio.pause();
        audio.play();
        this.isPlay = false;
      }
      if (this.isClose) {
        setTimeout(function () {
          var audio = document.querySelector("#audioClose");
          audio.pause();
          audio.play();
        }, 2000);
        this.isClose = false;
      }
    },
    async getListAll() {
      let res = await api.common.commonGet({
        apiName: "/ctripApiParam/listAll",
      });
      if (res.code == 200) {
        this.agentCodeList = res.data;

        // this.checkLoginStatus(res.data[0].agentCode);

        //这里要注意，如果是新增字段进去，要用set方式进去
        res.data.forEach((e, index) => {
          this.$set(this.agentCodeList[index], "status", "未知");
          this.$set(this.agentCodeList[index], "switch", "未知");
        });

        this.batchGetMessage();
      }
    },

    async getMessage(id) {
      let res = await api.common.commonGetDiy({
        url: "/ctrip/old/spring/getunreadnoticecountOrigin",
        baseURL: "/api",
        params: {
          agentCode: id,
        },
      });
      return res;
    },

    async getSwitch(id) {
      let res = await api.common.commonGetDiy({
        url: "/ctrip/old/spring/getTicketingSwitchOrigin",
        baseURL: "/api",
        raw: true,
        params: {
          agentCode: id,
        },
      });
      return res;
    },
    //并发获取消息

    async batchGetMessage() {
      let PromiseArr = [];
      let switchArr = [];
      for (let index = 0; index < this.agentCodeList.length; index++) {
        PromiseArr.push(this.getMessage(this.agentCodeList[index].agentCode));
        switchArr.push(this.getSwitch(this.agentCodeList[index].agentCode));
      }

      let _this = this;
      let resLast = await Promise.allSettled(PromiseArr);
      let switchRes = await Promise.allSettled(switchArr);
      resLast.forEach((element, index) => {
        if (element.status == "fulfilled" && element.value) {
          if (
            element.value.data.reschedule > 0 ||
            element.value.data.ticketing > 0
          ) {
            _this.$set(_this.agentCodeList[index], "status", "有新消息");
            this.isPlay = true;
          } else {
            _this.$set(_this.agentCodeList[index], "status", "暂无消息");
          }
        } else {
          _this.$set(_this.agentCodeList[index], "status", "未登录");
        }
      });

      switchRes.forEach((element, index) => {
        if (element.status == "fulfilled") {
          if (
            element.value.data.success &&
            element.value.data.success !== "false"
          ) {
            if (
              element.value.data.data &&
              element.value.data.data !== "false"
            ) {
              _this.$set(_this.agentCodeList[index], "switch", "开启");
            } else {
              _this.$set(_this.agentCodeList[index], "switch", "关闭");
              this.isClose = true;
            }
          } else {
            _this.$set(_this.agentCodeList[index], "switch", "未登录");
          }

          // if (element.value.data.data) {
          //   _this.$set(_this.agentCodeList[index], "switch", "开启");

          // } else {
          //   _this.$set(_this.agentCodeList[index], "switch", "关闭");
          //   this.isClose = true;
          // }
        }
      });
      this.audioPlay();
    },
    async checkLoginStatus(id) {
      try {
        let res = await api.common.commonGetDiy({
          url: "/notice/getunreadnoticecount",
          baseURL: "/ctripApi",
          params: {
            gsdm: id,
          },
        });
        console.log(res);
      } catch (error) {
        this.$message.error("未登录携程平台！");
        this.loopRun = false;
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.audioCon {
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
}
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.inputFile {
  display: none;
}
.fullWidth-label {
  padding: 0 10px;
  width: 170px;
}
.avatar {
  width: 170px;
  height: 250px;
  display: block;
}
.font-green {
  color: #67c23a;
}
.font-red {
  color: #f56c6c;
}
.font-blue {
  color: #409eff;
}
</style>
